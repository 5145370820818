
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import type { FSXARichText } from 'fsxa-pattern-library'
import { ILink } from '../../shared/general/interfaces/ILink'
import { TVariant } from '../../shared/general/types/TCarousel'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import IVideo from '../../shared/general/interfaces/IVideo'

@Component({
  name: 'CarouselContent',
  components: {
    BaseButton: () => import('../base/BaseButton.vue'),
    BaseLink: () => import('../base/BaseLink.vue'),
    Checkmark: () => import('../svgs/Checkmark.vue'),
    FSXARichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class CarouselContent extends Vue {
  @Prop({ default: () => [] }) headlines! : FSXARichText[]

  @Prop({ default: '' }) headline! : string

  @Prop({ default: () => [] }) links! : ILink[]

  @Prop({ default: '' }) text! : string

  @Prop({ required: true }) variant! : TVariant

  @Prop() checkmarks ?: RichTextElement[][]

  @Prop() video ?: IVideo

  private headlineSizes : Record<TVariant, string> = {
    'career-stage': '', // has no headline
    carousel: 'text-xl sm:text-2xl',
    'stage-large': 'text-2xl sm:text-3xl md:text-4xl lg:text-5xl',
    'stage-medium': 'text-2xl sm:text-3xl md:text-3xl lg:text-4xl',
  }

  private extractCheckMarkContent (richTextElement : RichTextElement[]) : RichTextElement[] {
    // root block element contains styling for headline which we don't want
    return richTextElement.flatMap((element) => ((Array.isArray(element.content) && element.type === 'block') ? element.content : element))
  }

  private get textClasses () : string {
    switch (this.variant) {
      case 'career-stage': return '' // has no text
      case 'carousel': return 'text-base'
      case 'stage-large': return 'text-lg'
      case 'stage-medium': return 'text-lg'
      default: return ''
    }
  }

  private get videoButtonLabel () : string {
    return globalLabelAsString('show_video_label')
  }

  private get showVideoButton () : boolean {
    return !!this.video?.id || !!this.video?.htmlPlayerElements?.mp4
  }

  @Emit('open-lightbox')
  private openLightbox () : void {}
}
