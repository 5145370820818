var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col text-white z-10 gap-4"},[(_vm.headlines.length)?_c('header',{staticClass:"text-opacity-100",class:_vm.headlineSizes[_vm.variant]},_vm._l((_vm.headlines),function(headline,index){return _c('FSXARichText',{key:index,attrs:{"content":headline}})}),1):(_vm.headline)?_c('header',{staticClass:"text-opacity-100",class:_vm.headlineSizes[_vm.variant]},[_vm._v("\n    "+_vm._s(_vm.headline)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.text.trim().length)?_c('p',{staticClass:"font-normal pb-4",class:_vm.textClasses},[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.showVideoButton)?_c('BaseButton',{staticClass:"!w-full sm:!w-auto max-w-fit",class:[_vm.variant !== 'carousel' ? 'items-center justify-center' : ''],attrs:{"label":_vm.videoButtonLabel,"variant":"accent","size":_vm.variant === 'carousel' ? 'small' : 'large',"icon":"play"},on:{"click":_vm.openLightbox}}):_c('div',{staticClass:"flex flex-col gap-4"},[(!!_vm.checkmarks)?_c('div',{staticClass:"flex flex-col gap-y-3 sm:gap-y-4"},_vm._l((_vm.checkmarks),function(checkmark,index){return _c('div',{key:'checkmark-' + index,staticClass:"flex flex-row flex-grow gap-3"},[_c('Checkmark',{staticClass:"shrink-0 relative sm:top-1 w-[26px] h-[26px] sm:w-[30px] sm:h-[30px]",attrs:{"alt":"checkmark"}}),_vm._v(" "),_c('FSXARichText',{staticClass:"relative text-xl sm:text-3xl md:text-2xl lg:text-3xl",attrs:{"content":_vm.extractCheckMarkContent(checkmark)}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.links.length)?_c('div',{staticClass:"grow-0 flex flex-wrap gap-4"},_vm._l((_vm.links),function(link,index){return _c('BaseLink',_vm._b({key:index},'BaseLink',{
          ...link,
          ...(_vm.variant === 'carousel' ? {
            buttonVariant: 'outline',
            buttonSize: 'small',
            buttonInverted: true,
          } : {
            buttonVariant: index === 0 ? 'accent' : 'outline',
            buttonSize: 'large',
            buttonInverted: index !== 0,
          }),
        },false))}),1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }