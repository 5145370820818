
import type { FlickingOptions, Flicking } from '@egjs/vue-flicking'
import {
  Component, Inject, Prop, Vue,
} from 'nuxt-property-decorator'
import { TCarouselSlide, TVariant } from '../../shared/general/types/TCarousel'
import CarouselContent from './CarouselContent.vue'
import IImageVideoContent from '../../shared/general/interfaces/IImageVideoContent'
import { openLightbox } from '../../shared/general/services/LightboxService'
import BasePicture from '../base/BasePicture.vue'

type ComponentEvent = {
  index : number
}

@Component({
  name: 'Carousel',
  components: {
    BasePicture,
    CarouselContent,
    CarouselControls: () => import('./CarouselControls.vue'),
    DesignLogo: () => import('../svgs/DesignLogo.vue'),
    Flicking: async () => (await import('@egjs/vue-flicking')).Flicking,
  },
})
export default class Carousel extends Vue {
  @Prop({ default: false }) showDesignLogo! : boolean

  @Prop({ required: true }) slides! : TCarouselSlide[]

  @Prop({ required: true }) variant! : TVariant

  @Inject({ from: 'carouselUsedInContentProjection', default: false }) usedInContentProjection! : boolean

  $refs! : {
    mobileCarousel ?: Flicking
    desktopCarousel ?: Flicking
  }

  private currentIndex : number = 0

  private flickingOptions : FlickingOptions = {
    noPanelStyleOverride: true, // Enabling this option will not change width/height style of the panels if 'panelsPerView' is enabled.
    horizontal: true,
    circularFallback: 'linear',
    circular: true,
    panelsPerView: 1,
    preventDefaultOnDrag: true, // Whether to use the preventDefault when the user starts dragging (fixes scrolling workaround)
    preventClickOnDrag: false,
    align: 'prev',
    inputType: ['pointer'],
  } as FlickingOptions

  private resolutions : Record<TVariant, Record<number | 'max', string>> = {
    'career-stage': {
      max: '1920x400',
      1920: '1920x400',
      1284: '1320x400',
      900: '900x400',
      600: '600x400',
    },
    carousel: {
      max: '850x300',
      900: '850x300',
      600: '600x300',
    },
    'stage-large': {
      max: '1920x580',
      1920: '1920x580',
      1284: '1320x580',
      900: '900x580',
      600: '600x580',
    },
    'stage-medium': {
      max: '1920x400',
      1920: '1920x400',
      1284: '1320x400',
      900: '900x400',
      600: '600x400',
    },
  }

  private contentPadding : Record<TVariant, string> = {
    'career-stage': 'p-6 sm:p-8 lg:p-12',
    'stage-medium': 'p-6 sm:p-8',
    'stage-large': 'p-6 sm:pt-8 sm:pb-8 sm:pl-12 sm:pr-8 md:p-10 lg:p-12',
    carousel: 'p-6 sm:px-8 sm:py-6 md:p-8',
  }

  private minMaxHeight : Record<TVariant, string> = {
    'career-stage': 'md:min-h-[480px] md:max-h-[480px]',
    'stage-medium': 'lg:min-h-[480px] lg:max-h-[480px]',
    'stage-large': 'md:min-h-[580px] lg:min-h-[640px] lg:max-h-[640px]',
    carousel: 'min-h-[240px] sm:min-h-[300px]',
  }

  private async moveToSlide (index : number) : Promise<void> {
    if (!this.$refs.desktopCarousel || !this.$refs.mobileCarousel) return
    try {
      await this.$refs.desktopCarousel.moveTo(index)
      await this.$refs.mobileCarousel.moveTo(index)
    } catch (error) { /* ignore errors */ }
  }

  private get showControls () : boolean {
    return this.slides.length > 1
  }

  private get currentSlide () : TCarouselSlide {
    return this.slides[this.currentIndex]
  }

  private updateIndex (e : ComponentEvent) : void {
    this.currentIndex = e.index
  }

  private showLightbox () : void {
    const { image } = this.currentSlide
    const { video } = this.currentSlide.content

    const content : IImageVideoContent = (video?.id && !['html5'].includes(video?.format || '')) ? {
      tag: 'BaseImageVideo',
      props: {
        autoplay: true,
        image,
        inlineVideo: true, // without this the video will not show on click
        lightbox: true,
        mediaType: 'video',
        video,
      },
    } : {
      tag: 'BaseHtmlPlayer',
      props: {
        htmlPlayerElements: video?.htmlPlayerElements,
        image,
        mediaType: 'video',
      },
    }
    openLightbox(content)
  }
}
